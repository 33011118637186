import {
  getConsent,
  getGlobalIdentityInstance,
  subscribeToConsent,
} from "@vgno/utils";

type EngagementProps = {
  elementId: string;
  elementName: string;
  target?: {
    url: string;
  };
};

const trackEngagement = (
  type: string,
  { elementId, elementName, target }: EngagementProps,
) => {
  window.pulse?.((sdk) => {
    sdk.track("trackerEvent", {
      type,
      action: type,
      object: {
        "@id": `sdrn:vg:article:${document.documentElement.dataset.id}:element:${elementId}`,
        type: "UIElement",
        name: elementName,
      },
      target,
    });
  });
};

export class BrazeSlideUp extends HTMLElement {
  position = "0";
  type = "article";

  constructor() {
    super();
    this.position = this.dataset.position || "0";
    this.type = this.dataset.type || "article";
    this.hidden = true;
  }

  connectedCallback() {
    this.initializeBraze();
  }

  async initializeBraze() {
    await this.waitForConsent();

    const identity = await getGlobalIdentityInstance();
    if (!identity) return console.warn("[@vgno/braze] Identity not found");
    if (!(await identity.isLoggedIn())) return;

    // @ts-expect-error - Load ESM from CDN
    const braze = await import("https://esm.vg.no/@braze/web-sdk@5.8.1");
    braze.initialize("d1240f31-a44b-4a02-9d5a-30677794528d", {
      baseUrl: "sdk.fra-01.braze.eu",
      doNotLoadFontAwesome: true,
    });

    const userId = await identity.getUserId();

    braze.changeUser(`sdrn:spid.no:user:${userId}`);
    braze.openSession();

    braze.subscribeToInAppMessage(function (inAppMessage) {
      trackEngagement("View", {
        elementId: "braze-slideup",
        elementName: `braze-slideup: ${inAppMessage.message}`,
        target: { url: inAppMessage.uri || "braze-button-without-href" },
      });

      inAppMessage.subscribeToClickedEvent(function () {
        trackEngagement("Click", {
          elementId: "braze-slideup",
          elementName: `braze-slideup: ${inAppMessage.message}`,
          target: { url: inAppMessage.uri || "braze-button-without-href" },
        });
      });

      braze.showInAppMessage(inAppMessage);
    });
  }

  waitForConsent() {
    return new Promise<void>((resolve) => {
      getConsent("CMP:marketing").then((consent) => {
        if (consent === "1") {
          resolve();
          return;
        }

        subscribeToConsent("CMP:marketing", (newConsent) => {
          if (newConsent === "1") resolve();
        });
      });
    });
  }
}
